import React from 'react';
import * as styles from './accountSuccess.module.css';


import Container from '../components/Container';
import Layout from '../components/Layout/Layout';

const OrderConfirmPage = (props) => {
  return (
    <Layout disablePaddingBottom>
      <Container size={'medium'}>
        <div className={styles.root}>
          <h1>Thank You!</h1>
          <h4>
            We are now processing your order.
          </h4>

          <h5> We'll email you to confirm your order and figure out payment. </h5>



        </div>
      </Container>
    </Layout>
  );
};

export default OrderConfirmPage;
